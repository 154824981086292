import React, { useState } from 'react';
import './custom-tabs-navigation.styles.scss';
import { useTranslation } from 'react-i18next';

interface CustomToggleProps {
	onToggle: (enabled: boolean) => void;
	toggledState?: boolean;
}

const CustomToggle: React.FC<CustomToggleProps> = ({ onToggle, toggledState = false }) => {
	const [isEnabled, setIsEnabled] = useState(toggledState);
	const [t] = useTranslation();

	const handleToggle = () => {
		const newState = !isEnabled;
		setIsEnabled(newState);
		onToggle(newState);
	};

	return (
		<div className='custom-toggle-container' onClick={handleToggle}>
			<div className='toggle-icon'>
				{toggledState ? (
					<svg xmlns='http://www.w3.org/2000/svg' width='43' height='24' viewBox='0 0 43 24' fill='none'>
						<path
							d='M42.5 12C42.5 18.6274 37.1274 24 30.5 24L12.5 24C5.87258 24 0.5 18.6274 0.500001 12C0.500002 5.37258 5.87259 -3.20207e-06 12.5 -2.62268e-06L30.5 -1.04907e-06C37.1274 -4.69686e-07 42.5 5.37258 42.5 12Z'
							fill='#8BC34A'
						/>
						<circle cx='30.5' cy='12' r='9' transform='rotate(-180 30.5 12)' fill='white' />
					</svg>
				) : (
					<svg xmlns='http://www.w3.org/2000/svg' width='43' height='24' viewBox='0 0 43 24' fill='none'>
						<path
							d='M0.5 12C0.5 5.37258 5.87258 0 12.5 0L30.5 0C37.1274 0 42.5 5.37258 42.5 12C42.5 18.6274 37.1274 24 30.5 24L12.5 24C5.87258 24 0.5 18.6274 0.5 12Z'
							fill='#BFBFBF'
						/>
						<circle cx='12.5' cy='12' r='9' fill='white' />
					</svg>
				)}
			</div>
			<span className='custom-label'>{t('toggle_versions_label')}</span>
		</div>
	);
};

export default CustomToggle;
