import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { MainMediaAdditionalItemsModal } from '../../additional-image-media/sub-components/main-media-additional-images-modal.component';
import {
	filterMainMediaImageSubTypes,
	findEmptyItemArrIndex,
	findMainMediaItemAlreadySelected,
	getAvailableVersions,
	removeMainMediaAdditionalItem,
} from '../helpers/additional-main-media-images.helper';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { MainMediaMetadaDataVersionsAdditionalItemInfoCard } from './main-media-metadata-versions-additional-items-info-card';
import './styles.scss';
import { addMainMediaIfNotExists, removeMainMedia, updateMainMediaInList } from '../../../../Media/helpers/MainMediaListingContainerHelper';
import { ContentTypes } from '../../../../../../../constants/content-types';
import { actionService } from '../../../../../../../App';
import { IMAGE_MEDIA_ADD } from '../../../../../../../store/action-creators/ImageActionCreator';

type Properties = {
	mainMedia: MainMedia[];
	onChange?: (mainMedia: MainMedia[]) => void;
};

export const MainMediaMetadataVersionsAdditionalImageSelect: FunctionComponent<Properties> = ({ mainMedia, onChange }) => {
	const availableVersions = getAvailableVersions();
	const [t] = useTranslation();
	const [toggle, setToggle] = useState<boolean>(false);
	const [mainMediaImageItems, setMainMediaImageItems] = useState<MainMedia[]>(
		filterMainMediaImageSubTypes(mainMedia, availableVersions && availableVersions.length > 0 ? availableVersions : []),
	);
	const [selectedMainMediaItemForEdit, setSelectedMainMediaItemForEdit] = useState<MainMedia>({} as MainMedia);

	useEffect(() => {
		setMainMediaImageItems(
			filterMainMediaImageSubTypes(mainMedia, availableVersions && availableVersions.length > 0 ? availableVersions : []),
		);
	}, [mainMedia && mainMedia.length, selectedMainMediaItemForEdit]);

	const handleAddMainMediaItemRow = (item: MainMedia) => {
		if (mainMedia && !findEmptyItemArrIndex(mainMedia)) {
			const newArr = [...mainMedia, item];
			setMainMediaImageItems(newArr);

			const mainMediaList = addMainMediaIfNotExists(item, mainMedia ? mainMedia : []);
			if (item.resourceType === ContentTypes.IMAGE) {
				actionService.emitGeneralAction(IMAGE_MEDIA_ADD);
			}
			onChange && onChange(mainMediaList);
		}
	};

	const handleDeleteRow = (itemId: string, item: MainMedia) => {
		const newMainMediaItemsArr = removeMainMediaAdditionalItem(mainMediaImageItems, itemId, item.resourceSubtype ? item.resourceSubtype : '');
		setMainMediaImageItems(newMainMediaItemsArr);

		const mainMediaList = removeMainMedia(
			item.resourceType ? item.resourceType : '',
			item.resourceSubtype ? item.resourceSubtype : '',
			mainMedia ? mainMedia : [],
		);
		onChange && onChange(mainMediaList);
	};

	const onMainMediaAdditionalImageSelect = (mainMediaItem: MainMedia) => {
		let isAlreadyExist = findMainMediaItemAlreadySelected(mainMediaImageItems, mainMediaItem);

		if (isAlreadyExist) {
			const mainMediaList = updateMainMediaInList(mainMediaItem, mainMedia ? mainMedia : []);
			if (mainMediaItem.resourceType === ContentTypes.IMAGE) {
				actionService.emitGeneralAction(IMAGE_MEDIA_ADD);
			}
			onChange && onChange(mainMediaList);
			setSelectedMainMediaItemForEdit({} as MainMedia);
		} else {
			handleAddMainMediaItemRow(mainMediaItem);

			const mainMediaList = addMainMediaIfNotExists(mainMediaItem, mainMedia ? mainMedia : []);
			if (mainMediaItem.resourceType === ContentTypes.IMAGE) {
				actionService.emitGeneralAction(IMAGE_MEDIA_ADD);
			}
			onChange && onChange(mainMediaList);
			setSelectedMainMediaItemForEdit({} as MainMedia);
		}
	};

	const selectMainMediaItemForEdit = (item: MainMedia, newItem: boolean = true) => {
		newItem ? setSelectedMainMediaItemForEdit({} as MainMedia) : setSelectedMainMediaItemForEdit(item);
	};

	const removeSelectedMainMediaItem = () => {
		setSelectedMainMediaItemForEdit({} as MainMedia);
	};

	const toggleModal = () => {
		setToggle(!toggle);
	};

	return (
		<>
			<Label className='mb-2 mt-2 flex-row'>
				<strong>{t('additional_main_media_image')}</strong>
			</Label>
			{mainMediaImageItems &&
				mainMediaImageItems.length > 0 &&
				mainMediaImageItems.map(
					(item: MainMedia, index: number) =>
						item.resourceId &&
						item.resourceId.length > 0 && (
							<div className='main-media-images-list' key={index}>
								<MainMediaMetadaDataVersionsAdditionalItemInfoCard
									item={item}
									setToggle={setToggle}
									handleDeleteRow={handleDeleteRow}
									index={index}
									setSelectedItemForEdit={selectMainMediaItemForEdit}
								/>
							</div>
						),
				)}
			{toggle && (
				<MainMediaAdditionalItemsModal
					toggleModal={toggleModal}
					isOpen={toggle}
					initialAdd={mainMediaImageItems && mainMediaImageItems.length < 1}
					handleDeleteRow={handleDeleteRow}
					removeSelectedItem={removeSelectedMainMediaItem}
					pickNewItem={onMainMediaAdditionalImageSelect}
					selectedItemForEdit={selectedMainMediaItemForEdit}
					mainMediaItems={mainMediaImageItems}
					featureType={FeatureTypes.MULTIPLE_METADATA}
				/>
			)}
			<Row>
				<Button className='ml-1' color='primary' outline onClick={() => toggleModal()}>
					<i className='fa fa-plus mx-1' />
					{t('add_additional_image')}
				</Button>
			</Row>
		</>
	);
};
