import * as React from 'react';
import { FormGroup } from 'reactstrap';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';
import { DebounceInput } from 'react-debounce-input';
import EmojiContainer from '../../Shared/emoticons/emoji-container';

type TitleProps = {
	t: any;
	onChange: any;
	value: string;
	disabled?: boolean;
	timeoutValue?: number;
	showValidationMessage?: boolean;
	isMetaDataVersion?: boolean;
};

type State = {
	selectionEnd: number;
};

export class Title extends React.Component<TitleProps, State> {
	private inputRef: any;
	constructor(props: any) {
		super(props);
		this.state = {
			selectionEnd: 0,
		};
		this.inputRef = null;
	}

	setRef = (elem: any) => (this.inputRef = elem);

	onChangeText = (e: any) => this.props.onChange(e.target.value);

	setSelectionEnd = (end: number) => this.setState({ ...this.state, selectionEnd: end });

	onBlur = (e: any) => this.setSelectionEnd(e.target.selectionEnd);

	render() {
		const { onChange, t, value, disabled, timeoutValue, showValidationMessage, isMetaDataVersion } = this.props;
		const { selectionEnd } = this.state;
		const showErrorValidationMessage = !showValidationMessage && isMetaDataVersion ? showValidationMessage : true;

		return (
			<>
				<FormGroup className='d-flex position-relative'>
					<DebounceInput
						type='text'
						inputRef={this.setRef}
						id='title'
						value={value ? value : ''}
						onBlur={this.onBlur}
						onChange={this.onChangeText}
						debounceTimeout={timeoutValue}
						className='form-control-lg form-control title-input'
						placeholder={t('title_placeholder')}
						required
						disabled={!!disabled}
					/>
					<EmojiContainer
						t={t}
						setSelectionEnd={this.setSelectionEnd}
						inputRef={this.inputRef}
						inputValue={value ? value : ''}
						selectionEnd={selectionEnd}
						onChange={onChange}
						calledFrom='title'
						disabled={!!disabled}
					/>
				</FormGroup>
				{showErrorValidationMessage && <ErrorAsync t={t} errorType='title' />}
				{showErrorValidationMessage && <ErrorAsync t={t} errorType='name' />}
			</>
		);
	}
}
