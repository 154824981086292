import { Label } from 'reactstrap';
import React, { useState } from 'react';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {
	DATA_QA_ATTRIBUTES,
	filterMainMediaImageSubTypesAvailableOptions,
	returnCorrectFeatureConfigForMainMediaSubTypes,
	subTypesToOptions,
	returnCorrectMapperForMainMediaSubTypes,
} from '../helpers/main-media-additional-items.helper';
import { featuresService } from '../../../../../../../App';

type Properties = {
	initialAdd: boolean;
	mainMediaItem: MainMedia;
	onMainMediaItemSubTypeSelect: (subType: string) => void;
	mainMediaItems: MainMedia[];
	featureType: string;
};

export const MainMediaImageSubTypeSelect: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const featureConfig = featuresService.getFeatureConfig(props.featureType);
	const options = filterMainMediaImageSubTypesAvailableOptions(
		props.mainMediaItem,
		returnCorrectFeatureConfigForMainMediaSubTypes(props.featureType, featureConfig),
	);
	const [selectedType, setSelectedType] = useState<string>(props.initialAdd ? '' : props.mainMediaItem.resourceSubtype);

	const onItemSubTypeSelect = (type: string) => {
		setSelectedType(type);
		props.onMainMediaItemSubTypeSelect(type);
	};

	return (
		<div data-qa={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ITEM_SUB_TYPE}>
			<Label htmlFor={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ITEM_SUB_TYPE}>
				<strong>{t('item_sub_type')}</strong>
			</Label>
			<span className='text-danger'>*</span>
			<Select
				id={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ITEM_SUB_TYPE}
				value={returnCorrectMapperForMainMediaSubTypes(props.featureType, selectedType, t)}
				options={subTypesToOptions(props.featureType, options, t)}
				onChange={(selection: any) => selection && onItemSubTypeSelect(selection.value)}
				placeholder={t('select')}
				noOptionsMessage={(inputValue) => inputValue && t('no_options')}
			/>
		</div>
	);
};
