import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import EmojiContainer from '../../Shared/emoticons/emoji-container';
import { FormGroup } from 'reactstrap';
import ErrorAsync from '../../BaseComponents/ErrorComponent/ErrorAsync';

type StrapLineProps = {
	t: any;
	onChange: any;
	value?: string;
	disabled?: boolean;
};

type State = {
	selectionEnd: number;
};

export class StrapLine extends React.Component<StrapLineProps, State> {
	private inputRef: any;
	constructor(props: any) {
		super(props);
		this.state = {
			selectionEnd: 0,
		};
		this.inputRef = null;
	}

	setRef = (elem: any) => (this.inputRef = elem);

	onChangeText = (e: any) => {
		this.props.onChange(e.target.value);
	};

	setSelectionEnd = (end: number) => this.setState({ ...this.state, selectionEnd: end });

	onBlur = (e: any) => this.setSelectionEnd(e.target.selectionEnd);

	render() {
		const { t, value, onChange, disabled } = this.props;

		return (
			<>
				<FormGroup className='position-relative '>
					<DebounceInput
						type='text'
						inputRef={this.setRef}
						onBlur={this.onBlur}
						id='strap-line'
						onChange={this.onChangeText}
						value={value ? value : ''}
						className='mb-1 form-control form-control-lg strap-line-input'
						debounceTimeout={300}
						placeholder={t('strap_line_placeholder')}
						disabled={!!disabled}
					/>
					<EmojiContainer
						setSelectionEnd={this.setSelectionEnd}
						inputRef={this.inputRef}
						inputValue={value ? value : ''}
						selectionEnd={this.state.selectionEnd}
						t={t}
						onChange={onChange}
						calledFrom='strapline'
						disabled={!!disabled}
					/>
				</FormGroup>
				<ErrorAsync t={t} errorType='strapline' />
			</>
		);
	}
}
