import { MandatoryFieldsTypes } from '../../../constants/mandatory-fields-constants';
import { ContentTypes } from '../../../constants/content-types';
import { featuresService } from '../../../App';
import { FeatureTypes } from '../../../services/feature-service/features.enum';

export const checkAreMandatoryFieldsFilled = (content: any, mandatoryFields: any) => {
	const areFilledMandatoryFields = Object.keys(mandatoryFields).map((key: string) => {
		if (mandatoryFields[key] && key === ContentTypes.MAIN_MEDIA) {
			const hasMainImage = mainMediaHasImage(content);
			return hasMainImage.length > 0;
		} else {
			return !!(content[MandatoryFieldsTypes[key]] && content[MandatoryFieldsTypes[key]].length > 0);
		}
	});
	return !areFilledMandatoryFields.includes(false);
};

export const mainMediaHasImage = (content: any) => {
	if (content && content.mainMedia) {
		return content.mainMedia.filter((item: any) => item.resourceId);
	} else return [];
};

export const getMetadataVersionsList = (): {
	id: string;
	name: string;
	title: string;
	strapline: string;
	summary: string;
	enabled: boolean;
}[] => {
	const featureConfigData: Record<string, string> = featuresService.checkFeatureDataField(FeatureTypes.MULTIPLE_METADATA);
	const versionsList = featureConfigData && featureConfigData.versions;

	if (Array.isArray(versionsList) && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA)) {
		return versionsList.map((version: { id: string; name: string; title: string; strapline: string; summary: string; enabled: boolean }) => ({
			id: version.id,
			name: version.name,
			title: version.title,
			strapline: version.strapline,
			summary: version.summary,
			enabled: version.enabled,
		}));
	}

	return [];
};
