import React from 'react';
import Emoji from './emoji';
import { compose } from 'redux';
import { Input, Button } from 'reactstrap';
import { gringingFace } from './emoji-list';
import Scrollbar from 'react-custom-scrollbars';
import onClickOutside from 'react-onclickoutside';
import { search } from '../search-in-text/search-in-text.helper';
import { convertEmojiTitle } from './helpers/emoji-container.helpers';
import './styles/emoji-styles.scss';

type Properties = {
	onChange: (value: string) => void;
	inputValue: string;
	selectionEnd: number;
	inputRef: HTMLElement;
	setSelectionEnd: (selectionEnd: number) => void;
	t: any;
	calledFrom: string;
	closeOnSelect?: boolean;
	disabled?: boolean;
};

type State = {
	display: boolean;
	isHovering: boolean;
	hoveredEmoji: any;
	emoticons: any[];
};

class EmojiContainer extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			display: false,
			isHovering: false,
			hoveredEmoji: {},
			emoticons: [...window.SMP365.emojiList],
		};
	}

	componentDidUpdate(prevProps: any) {
		const { inputRef, selectionEnd } = this.props;

		if ((inputRef instanceof HTMLInputElement || inputRef instanceof HTMLTextAreaElement) && prevProps.selectionEnd !== selectionEnd) {
			this.state.display && inputRef.focus();
			inputRef.setSelectionRange(selectionEnd, selectionEnd);
		}
	}

	onSelectEmoji = (character: string) => {
		const { onChange, inputValue, selectionEnd, setSelectionEnd } = this.props;
		const newValue = inputValue.substring(0, selectionEnd) + character + inputValue.substring(selectionEnd);
		onChange(newValue);
		setSelectionEnd(selectionEnd + character.length);

		if (this.props.closeOnSelect) {
			this.toggleEmojiContainer(false);
		}
	};

	onEmojiSearch = (event: any) => {
		search(event.target.value.trim(), this.props.t, [...window.SMP365.emojiList], 'title').then((list: any[]) => {
			this.setState({ ...this.state, emoticons: list });
		});
	};

	toggleEmojiContainer = (isShown: boolean) => {
		if (isShown && (this.props.inputRef instanceof HTMLInputElement || this.props.inputRef instanceof HTMLTextAreaElement)) {
			this.props.inputRef.focus();
		}

		this.setState({ ...this.state, display: isShown });
	};

	toggleEmojiHover = (isHovering: boolean, emoji: any) => {
		this.setState({ ...this.state, isHovering, hoveredEmoji: emoji });
	};

	handleClickOutside = () => this.toggleEmojiContainer(false);

	render() {
		const { display, emoticons, isHovering, hoveredEmoji } = this.state;
		const style = `bg-light animated fadeIn fadeOut emoji-list rounded shadow p-1 mt-1 ${display ? 'd-flex' : 'd-none'}`;

		return (
			<div id='emoji-select-container' className='d-flex emoji-container'>
				<Button
					id='emoji-button'
					color='light'
					className='m-1'
					onClick={() => (!this.props.disabled ? this.toggleEmojiContainer(!display) : null)}
					disabled={this.props.disabled}
				>
					<Emoji title={gringingFace.title} character={gringingFace.character} />
				</Button>
				<div className={style}>
					<div className='m-1 bg-white'>
						<Input
							id={`emoji-search-input-${this.props.calledFrom}`}
							bsSize='sm'
							type='text'
							onChange={this.onEmojiSearch}
							placeholder={`${this.props.t('search')} emoji`}
							className='form-control'
							disabled={this.props.disabled}
						/>
					</div>
					<div className='mx-1 mt-1 bg-white rounded border border-muted scrollbar-container'>
						<Scrollbar className='p-1'>
							{emoticons.map((emoji: any) => {
								return (
									<Button
										key={convertEmojiTitle(emoji.title)}
										onMouseEnter={() => this.toggleEmojiHover(true, emoji)}
										onMouseLeave={() => this.toggleEmojiHover(false, {})}
										id={convertEmojiTitle(emoji.title)}
										color='white'
										className='p-0 m-1 emoji-button'
										onClick={() => this.onSelectEmoji(emoji.character)}
										disabled={this.props.disabled}
									>
										<Emoji title={emoji.title} character={emoji.character} />
									</Button>
								);
							})}
						</Scrollbar>
					</div>
					{isHovering && (
						<div className='mx-1 animated fadeIn text-uppercase d-flex align-items-center'>
							<small className='text-muted mr-auto w-80 text-truncate'>{this.props.t(hoveredEmoji.title)}</small>
							<div style={{ fontSize: '18px' }}>
								<Emoji title={hoveredEmoji.title} character={hoveredEmoji.character} />
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default compose(onClickOutside)(EmojiContainer);
