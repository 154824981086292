import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { checkIfCorrectTypes } from '../../additional-image-media/helpers/main-media-additional-items.helper';
import { ContentTypes } from '../../../../../../../constants/content-types';
import MainMediaModel from '../../../../../../../models/v2/main-media/main-media.model';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { formatSnakeCaseString } from '../../../../../../../global-helpers/global.helpers';

export enum DATA_QA_ATTRIBUTES {
	MAIN_MEDIA_ITEM_SUB_TYPE = 'main-media-image-metadata-versions-type-select',
	MAIN_MEDIA_ADDITIONAL_ITEMS_MODAL = 'main-media-additional-metadata-versions-modal',
	MAIN_MEDIA_ADDITIONAL_IMAGE_SELECT_COMPONENT = 'main-media-additional-metadata-versions-image-select-component',
	MAIN_MEDIA_ADDITIONAL_IMAGES_LIST = 'main-media-additional-metadata-versions-images-list',
	MAIN_MEDIA_ADDITIONAL_IMAGES_CONTENT_MODAL = 'main-media-additional-metadata-versions-images-content-modal',
}

export const SUPPORTED_CONTENT_TYPES = [ContentTypes.ARTICLE];

export const getAvailableVersions = () => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.MULTIPLE_METADATA);

	if (featureConfig && featureConfig.versions) {
		return featureConfig.versions.map((version: { id: string }) => version.id);
	}
	return [];
};

export const getAvailableVersionsNames = () => {
	const featureConfig = featuresService.getFeatureConfig(FeatureTypes.MULTIPLE_METADATA);

	if (featureConfig && featureConfig.versions) {
		return featureConfig.versions.map((version: { id: string; name: string }) => version.name);
	}
	return [];
};

export const checkIfFeatureEnabledAndCorrectContentType = (contentType: string) => {
	return (
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MULTIPLE_METADATA) && checkIfCorrectTypes(contentType, SUPPORTED_CONTENT_TYPES)
	);
};

export const metadataVersionsSubTypeToOption = (type: string) => {
	if (type) {
		return {
			value: type,
			label: formatSnakeCaseString(type),
		};
	}
	return {};
};

export const metadataVersionsSubTypesToOptions = (types: string[]) => {
	if (types && types.length > 0) {
		return types.map((type: string) => metadataVersionsSubTypeToOption(type));
	}
	return [];
};

export const findMainMediaItemAlreadySelected = (mainMediaItems: MainMedia[], currentlySelectedItem: MainMediaModel) => {
	return (
		mainMediaItems &&
		mainMediaItems.findIndex((item: MainMedia) => item && item.resourceSubtype == currentlySelectedItem.resourceSubtype) >= 0
	);
};

export const findEmptyItemArrIndex = (model: MainMedia[]) => {
	if (model) {
		return model.findIndex((modelData) => modelData.resourceId === '' && modelData.resourceType === '');
	}
};

export const emptyItemRowAvailable = (items: MainMedia[]) => {
	const newRowIndex = findEmptyItemArrIndex(items);
	return newRowIndex && newRowIndex >= 0;
};

export const filterMainMediaImageSubTypes = (items: MainMedia[], availableSubTypes: string[]) => {
	return items && items.filter((item) => checkIfCorrectTypes(item.resourceSubtype ? item.resourceSubtype : '', availableSubTypes));
};

export const filterMainMediaImageSubTypesAvailableOptions = (item: MainMedia, availableOptions: string[]) => {
	return availableOptions && availableOptions.filter((option) => option !== item.resourceSubtype);
};

export const removeMainMediaAdditionalItem = (items: MainMedia[], itemToBeRemovedId: string, subType: string) => {
	return items.filter((item) => item.resourceId !== itemToBeRemovedId && item.resourceSubtype !== subType);
};
