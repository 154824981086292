import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import MainMedia from '../../../../../../../models/mainmedia/MainMedia';
import { useTranslation } from 'react-i18next';
import { MainMediaImageSubTypeSelect } from './main-media-image-sub-type-select.component';
import { ContentTypes } from '../../../../../../../constants/content-types';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';
import { extractMainContentTypeBasedOnUrl } from '../../../../../../../global-helpers/global.helpers';
import { MainMediaAdditionalImage } from './main-media-additional-images.component';
import { DATA_QA_ATTRIBUTES } from '../helpers/main-media-additional-items.helper';

type Properties = {
	isOpen: boolean;
	toggleModal: (isOpen: boolean) => void;
	pickNewItem: Function;
	initialAdd: boolean;
	selectedItemForEdit: MainMedia;
	removeSelectedItem: Function;
	handleDeleteRow: (id: string, item: MainMedia) => void;
	mainMediaItems: MainMedia[];
	featureType: string;
};

export const MainMediaAdditionalItemsModal: React.FunctionComponent<Properties> = ({
	toggleModal,
	isOpen,
	pickNewItem,
	selectedItemForEdit,
	initialAdd,
	removeSelectedItem,
	handleDeleteRow,
	mainMediaItems,
	featureType,
}) => {
	const [t] = useTranslation();
	const [tempSelectedItem, setTempSelectedItem] = useState(initialAdd ? ({} as MainMedia) : selectedItemForEdit);
	const [selectedItem, setSelectedItem] = useState(initialAdd ? ({} as MainMedia) : selectedItemForEdit);
	const project = useSelector((state: AppState) => state.project.currentProject);
	const contentType = extractMainContentTypeBasedOnUrl();
	const imageMediaErrorIndex = mainMediaItems && mainMediaItems.findIndex((media) => media.resourceType === ContentTypes.IMAGE);

	const onMainMediaItemSubTypeChange = (type: string) => {
		if (isValidItem) {
			handleDeleteRow(selectedItem.resourceId, selectedItem);
			setSelectedItem(MainMedia.builder(tempSelectedItem).withResourceSubtype(type).build());
		} else {
			setSelectedItem(MainMedia.builder(selectedItem).withResourceSubtype(type).build());
		}
	};

	const onMainMediaChange = (mainMedia: MainMedia) => {
		setSelectedItem(MainMedia.builder(mainMedia).build());
	};

	const validDescription =
		(selectedItem.description && selectedItem.description.length <= 255) || selectedItem.description === '' || !selectedItem.description;
	const isValidItem =
		selectedItem.resourceId &&
		selectedItem.resourceId.length > 0 &&
		selectedItem.resourceSubtype &&
		selectedItem.resourceSubtype.length > 0 &&
		selectedItem.resourceType &&
		validDescription;

	return (
		<div data-qa={DATA_QA_ATTRIBUTES.MAIN_MEDIA_ADDITIONAL_ITEMS_MODAL}>
			<Modal isOpen={isOpen} size='lg'>
				<ModalHeader
					toggle={() => {
						!initialAdd && pickNewItem(tempSelectedItem);
						removeSelectedItem();
						toggleModal(!isOpen);
					}}
				>
					{t('add_additional_image')}
				</ModalHeader>
				<ModalBody>
					<div data-children='.item'>
						<div className='item'>
							<MainMediaImageSubTypeSelect
								initialAdd={initialAdd}
								mainMediaItem={selectedItem}
								mainMediaItems={mainMediaItems}
								onMainMediaItemSubTypeSelect={onMainMediaItemSubTypeChange}
								featureType={featureType}
							/>
						</div>
					</div>
					<hr />
					{selectedItem.resourceSubtype && selectedItem.resourceSubtype.length > 0 && (
						<div data-children='.item' className='mt-3'>
							<div className='item'>
								<MainMediaAdditionalImage
									contentType={contentType ? contentType : ''}
									media={selectedItem}
									mediaErrorIndex={imageMediaErrorIndex}
									onMainImageMediaUpdate={onMainMediaChange}
									projectOriginId={project ? project.originId : ''}
									additionalImagesModalOpened={isOpen}
								/>
							</div>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						id={'main-media-additional-items-modal-close-button'}
						color='danger'
						className='text-light'
						onClick={() => {
							!initialAdd && pickNewItem(tempSelectedItem);
							removeSelectedItem();
							toggleModal(!isOpen);
						}}
					>
						{t('close')}
					</Button>
					<Button
						disabled={!isValidItem}
						id={'main-media-additional-items-modal-add-button'}
						color='primary'
						className='text-light'
						onClick={() => {
							pickNewItem(selectedItem);
							toggleModal(!isOpen);
						}}
					>
						{t('add')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
