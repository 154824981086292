import React from 'react';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import './styles.scss';

type WordcountProps = {
	t: any;
	wordCount: number;
	charCount: number;
	wordCountComponentVariant?: boolean;
};

class WordCountComponent extends React.Component<WordcountProps> {
	isWordCountEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT);

	render() {
		const { t, wordCount, charCount, wordCountComponentVariant } = this.props;
		return this.isWordCountEnabled ? (
			<div className={wordCountComponentVariant ? 'word-count-container-variant' : 'word-count-container'}>
				<span style={{ background: wordCountComponentVariant ? 'none' : '#f0f3f5' }}>
					{`
			${t('words')}: `}
					{<span className={'word-count-stats'}>{wordCount}</span>},{`${t('characters')}: `}
					{<span className={'word-count-stats'}>{charCount}</span>}
				</span>
			</div>
		) : null;
	}
}

export default WordCountComponent;
