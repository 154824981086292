import React, { useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import './custom-tabs-navigation.styles.scss';
import CustomCheckbox from './custom-checkbox';
import { useTranslation } from 'react-i18next';
import CustomToggle from './custom-toggle';
import { ComponentClass, FC } from 'react';

interface StrapLineProps {
	t: any;
	onChange: (value: string) => void;
	value: string;
	disabled: boolean;
}

interface TitleProps {
	t: any;
	onChange: (value: string) => void;
	value: string;
	disabled: boolean;
	timeoutValue: number;
	showValidationMessage?: boolean;
	isMetaDataVersion?: boolean;
}

interface WordCountComponentProps {
	t: any;
	wordCount: number;
	charCount: number;
	wordCountComponentVariant?: boolean;
}

interface SummaryProps {
	t: any;
	onChange: (value: string) => void;
	value: string;
	disabled: boolean;
	timeoutValue: number;
	showValidation?: boolean;
	customCss?: boolean;
}

interface TabContentProps {
	id: string;
	title: string;
	strapline: string;
	description: string;
	onTitleChange: (title: string) => void;
	onStrapLineChange: (strapline: string) => void;
	onSummaryChange: (summary: string) => void;
	totalWordCount: number;
	totalCharCount: number;
	StrapLine: ComponentClass<StrapLineProps>;
	Title: ComponentClass<TitleProps>;
	WordCountComponent: ComponentClass<WordCountComponentProps>;
	Summary: FC<SummaryProps>;
	activeTab: string;
	enabled: boolean;
	onToggle: (enabled: boolean) => void;
	onCopyFromDefault: () => void;
}

interface CustomTabsProps {
	tabs: { id: string; name: string; content: TabContentProps }[];
	defaultContent: TabContentProps;
	StrapLine: ComponentClass<StrapLineProps>;
	Title: ComponentClass<TitleProps>;
	WordCountComponent: ComponentClass<WordCountComponentProps>;
	Summary: FC<SummaryProps>;
	setActiveTab: (tab: string) => void;
	activeTab: string;
	onToggleVersion: (tabId: string, enabled: boolean) => void;
	onCopyFromDefault: (tabId: string) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({
	tabs,
	defaultContent,
	StrapLine,
	Title,
	WordCountComponent,
	Summary,
	setActiveTab,
	activeTab,
	onToggleVersion,
	onCopyFromDefault,
}) => {
	const [t] = useTranslation();

	const handleToggleVersion = (tabId: string, enabled: boolean) => {
		onToggleVersion(tabId, enabled);
	};

	return (
		<div className='custom-tabs-container'>
			<ul className='nav nav-tabs'>
				<li
					className='nav-item'
					style={{
						background: activeTab === 'default' ? '#F5F5F5' : '',
						borderBottom: activeTab === 'default' ? 'none' : '',
					}}
					onClick={() => setActiveTab('default')}
				>
					<div className={`nav-link ${activeTab === 'default' ? 'active' : ''}`} onClick={() => setActiveTab('default')}>
						<span className='custom-tab-label' style={{ color: '#000' }}>
							{t('default')}
						</span>
					</div>
				</li>
				{tabs.map((tab) => (
					<li
						className='nav-item'
						style={{
							background: activeTab === tab.id ? '#F5F5F5' : '',
							borderBottom: activeTab === tab.id ? 'none' : '',
						}}
						key={tab.id}
						onClick={() => setActiveTab(tab.id)}
					>
						<div className={`nav-link ${activeTab === tab.id ? 'active' : ''}`} onClick={() => setActiveTab(tab.id)}>
							<span
								className='custom-tab-label'
								style={{
									color: tab.content.enabled ? '#000' : '#8C8C8C',
								}}
							>
								{tab.name}
							</span>
						</div>
					</li>
				))}
			</ul>

			<div className='tab-content'>
				{activeTab === 'default' && (
					<div className='tab-pane active'>
						<TabContent
							{...defaultContent}
							StrapLine={StrapLine}
							Title={Title}
							WordCountComponent={WordCountComponent}
							Summary={Summary}
							activeTab={activeTab}
							onCopyFromDefault={() => {}}
							enabled={true}
						/>
					</div>
				)}

				{tabs.map((tab) => (
					<div key={tab.id} className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`}>
						<TabContent
							{...tab.content}
							StrapLine={StrapLine}
							Title={Title}
							WordCountComponent={WordCountComponent}
							Summary={Summary}
							activeTab={activeTab}
							onToggle={(enabled: boolean) => handleToggleVersion(tab.id, enabled)}
							enabled={tab.content.enabled}
							title={tab.content.title}
							onCopyFromDefault={() => onCopyFromDefault(tab.id)}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

const TabContent: React.FC<TabContentProps> = ({
	id,
	title,
	strapline,
	description,
	onTitleChange,
	onStrapLineChange,
	onSummaryChange,
	totalWordCount,
	totalCharCount,
	StrapLine,
	Title,
	WordCountComponent,
	Summary,
	activeTab,
	enabled,
	onToggle,
	onCopyFromDefault,
}) => {
	const [checked, setChecked] = useState(false);
	const [t] = useTranslation();

	const handleCopyFromDefault = (checked: boolean) => {
		setChecked(checked);

		if (checked) {
			onCopyFromDefault();
		}
	};

	return (
		<div>
			{activeTab !== 'default' && <CustomToggle onToggle={onToggle} toggledState={enabled} />}

			<Row>
				<Col xs='12'>
					<Label className='custom-label' htmlFor={`title-${id}`}>
						{t('title')}
					</Label>
					<Row>
						<Col>
							<StrapLine t={t} onChange={(value: string) => onStrapLineChange(value)} value={strapline} disabled={!enabled} />
						</Col>
						<Col>
							<WordCountComponent t={t} wordCount={totalWordCount} charCount={totalCharCount} wordCountComponentVariant />
						</Col>
					</Row>
					<Title
						t={t}
						onChange={(value: string) => onTitleChange(value)}
						value={title}
						disabled={!enabled}
						timeoutValue={500}
						showValidationMessage={enabled}
						isMetaDataVersion
					/>
				</Col>
			</Row>
			<Row>
				<Col xs='12'>
					<Summary
						t={t}
						onChange={(value: string) => onSummaryChange(value)}
						showValidation={true}
						value={description}
						customCss
						disabled={!enabled}
						timeoutValue={500}
					/>
				</Col>
			</Row>

			{activeTab !== 'default' && (
				<CustomCheckbox
					checked={checked}
					onChange={(checked: boolean) => handleCopyFromDefault(checked)}
					label={t('copy_from_default')}
					disabled={!enabled}
				/>
			)}
		</div>
	);
};

export default CustomTabs;
